import {yup} from '@front-libs/core';
import {
  BaseUnitKey,
  IsMaintenanceContractKey,
  PermanentlyAssignedKey,
  PurchasedNationalExpenseKey,
  TaxIncludeKey,
  testDate,
  testInteger,
} from '../ProductImport/hooks';
import {BulkUpdateProductListElement} from './types';
import ExcelJs from 'exceljs';
import {getCellValue} from '@organisms/HospitalProductImporter';
import {START_DATA_ROW_NUMBER, ValidationMessages} from './constants';
import {WaysOfPurchaseLabel} from '@modules/hospital_products/types';
import {
  formatManagementId,
  formatSerialNumber,
  formatLotNumber,
  formatAssetRegisterNumber,
} from '@modules/hospital_products/utls';

export const objectSchema = yup.object({
  hashId: yup.string().required('ハッシュID' + ValidationMessages.required),
  managementId: yup
    .string()
    .required('管理番号' + ValidationMessages.required)
    .max(256, '管理番号は最大で256文字までです。'),
  rootCategory: yup.string(),
  narrowCategory: yup.string(),
  makerName: yup.string(),
  displayName: yup.string(),
  name: yup.string(),
  serialNumber: yup.string().max(256, 'シリアル番号は最大で256文字までです。'),
  lotNumber: yup.string().max(32, 'ロット番号は最大で32文字までです。'),
  permanentlyAssigned: yup.string().oneOf(['貸出不可', '貸出可'], '貸出区分が正しくありません。'),
  isBaseUnit: yup.string().oneOf(['親機', '子機', '非該当', ''], '親機・子機が正しくありません。'),
  dateOfPurchase: yup.string().test('dateOfPurchase', '購入日の日付形式が正しくありません。', testDate),
  optionalBarcode: yup.string(),
  gs1Barcode: yup.string(),
  waysOfPurchase: yup
    .string()
    .oneOf(['購入', 'リース', 'レンタル', '代替品', 'デモ機', '寄贈', '移管', ''], '購入区分が正しくありません。'),
  purchasedNationalExpense: yup.string().oneOf(['国費', '院費', ''], '購入元が正しくありません。'),
  deliveryPrice: yup.string().test('deliveryPrice', '納入価は整数でなくてはなりません。', testInteger),
  taxIncluded: yup.string().oneOf(['税込', '税抜き', ''], '税込/税抜きが正しくありません。'),
  assetRegisterNumber: yup.string(),
  legalDurableYear: yup.string().test('legalDurableYear', '院内耐用年数は整数でなくてはなりません。', testInteger),
  dateOfDisposal: yup.string().test('dateOfDisposal', '廃棄日の日付形式が正しくありません。', testDate),
  reasonOfDisposal: yup.string(),
  notes: yup.string(),
  notes2: yup.string(),
  notes3: yup.string(),
  rentalId: yup.string(),
  rentalDealerName: yup.string(),
  rentalFee: yup.string().test('rentalFee', 'レンタル金額(円)は整数でなくてはなりません。', testInteger),
  rentalStartDate: yup.string().test('rentalStartDate', 'レンタル開始日の日付形式が正しくありません。', testDate),
  rentalDueDate: yup.string().test('rentalDueDate', 'レンタル終了予定日の日付形式が正しくありません。', testDate),
  rentalReturnDate: yup.string().test('rentalReturnDate', 'レンタル機器返却日の日付形式が正しくありません。', testDate),
  demonstrationStartDate: yup
    .string()
    .test('demonstrationStartDate', 'デモ開始日の日付形式が正しくありません。', testDate),
  demonstrationEndDate: yup.string().test('demonstrationEndDate', 'デモ終了日の日付形式が正しくありません。', testDate),
  taxRate: yup.string().test('taxRate', '税率(%)は整数でなくてはなりません。', testInteger),
  leaseFee: yup.string().test('leaseFee', 'リース金額(円)は整数でなくてはなりません。', testInteger),
  isMaintenanceContract: yup.string().oneOf(['保守契約', '保守契約外'], '保守契約の形式が正しくありません。'),
});

export const validationSchema = yup.array(objectSchema).required();
const isUpdateHospitalProduct = (row: ExcelJs.Row, rowNumber: number, startDataRowNumber: number): boolean => {
  return rowNumber >= startDataRowNumber && row.getCell(1).text === '○';
};

export const validateAndBind = async ({dataSheet}: {dataSheet: ExcelJs.Worksheet}) => {
  const errorMessages: {managementId: string; propertyName: string; message: string}[] = [];
  const hospitalProductTableData: BulkUpdateProductListElement[] = [];
  dataSheet.eachRow((row, rowNumber) => {
    if (isUpdateHospitalProduct(row, rowNumber, START_DATA_ROW_NUMBER)) {
      hospitalProductTableData.push({
        hashId: (getCellValue(row.getCell(2), 'xlsx') as string) ?? '',
        managementId: formatManagementId((getCellValue(row.getCell(3), 'xlsx') as string) ?? ''),
        rootCategory: (getCellValue(row.getCell(4), 'xlsx') as string) ?? '',
        narrowCategory: (getCellValue(row.getCell(5), 'xlsx') as string) ?? '',
        displayName: (getCellValue(row.getCell(6), 'xlsx') as string) ?? '',
        name: (getCellValue(row.getCell(7), 'xlsx') as string) ?? '',
        makerName: (getCellValue(row.getCell(8), 'xlsx') as string) ?? '',
        serialNumber: formatSerialNumber((getCellValue(row.getCell(9), 'xlsx') as string) ?? ''),
        lotNumber: formatLotNumber((getCellValue(row.getCell(10), 'xlsx') as string) ?? ''),
        permanentlyAssigned: getCellValue(row.getCell(11), 'xlsx') as string as PermanentlyAssignedKey, // 11
        isBaseUnit: (getCellValue(row.getCell(12), 'xlsx') as string as BaseUnitKey) ?? '非該当',
        dateOfPurchase: (getCellValue(row.getCell(13), 'xlsx') as string) ?? '',
        optionalBarcode: (getCellValue(row.getCell(14), 'xlsx') as string) ?? '',
        gs1Barcode: (getCellValue(row.getCell(15), 'xlsx') as string) ?? '',
        waysOfPurchase: (getCellValue(row.getCell(16), 'xlsx') as string as WaysOfPurchaseLabel) ?? '',
        purchasedNationalExpense:
          (getCellValue(row.getCell(17), 'xlsx') as string as PurchasedNationalExpenseKey) ?? '',
        deliveryPrice: (getCellValue(row.getCell(18), 'xlsx') as string) ?? '',
        taxIncluded: (getCellValue(row.getCell(19), 'xlsx') as string as TaxIncludeKey) ?? '',
        taxRate: (getCellValue(row.getCell(20), 'xlsx') as string) ?? '',
        assetRegisterNumber: formatAssetRegisterNumber((getCellValue(row.getCell(21), 'xlsx') as string) ?? ''),
        legalDurableYear: (getCellValue(row.getCell(22), 'xlsx') as string) ?? '',
        dateOfDisposal: (getCellValue(row.getCell(23), 'xlsx') as string) ?? '',
        reasonOfDisposal: (getCellValue(row.getCell(24), 'xlsx') as string) ?? '',
        notes: (getCellValue(row.getCell(25), 'xlsx') as string) ?? '',
        notes2: (getCellValue(row.getCell(26), 'xlsx') as string) ?? '',
        notes3: (getCellValue(row.getCell(27), 'xlsx') as string) ?? '',
        leaseId: (getCellValue(row.getCell(28), 'xlsx') as string) ?? '',
        leaseDealerName: (getCellValue(row.getCell(29), 'xlsx') as string) ?? '',
        leaseFee: (getCellValue(row.getCell(30), 'xlsx') as string) ?? '',
        leaseStartDate: (getCellValue(row.getCell(31), 'xlsx') as string) ?? '',
        leaseDueDate: (getCellValue(row.getCell(32), 'xlsx') as string) ?? '',
        leaseReturnDate: (getCellValue(row.getCell(33), 'xlsx') as string) ?? '',
        rentalId: (getCellValue(row.getCell(34), 'xlsx') as string) ?? '',
        rentalDealerName: (getCellValue(row.getCell(35), 'xlsx') as string) ?? '',
        rentalFee: (getCellValue(row.getCell(36), 'xlsx') as string) ?? '',
        rentalStartDate: (getCellValue(row.getCell(37), 'xlsx') as string) ?? '',
        rentalDueDate: (getCellValue(row.getCell(38), 'xlsx') as string) ?? '',
        rentalReturnDate: (getCellValue(row.getCell(39), 'xlsx') as string) ?? '',
        purposeOfDemonstration: (getCellValue(row.getCell(40), 'xlsx') as string) ?? '',
        demonstrationStartDate: (getCellValue(row.getCell(41), 'xlsx') as string) ?? '',
        demonstrationEndDate: (getCellValue(row.getCell(42), 'xlsx') as string) ?? '',
        isMaintenanceContract: (getCellValue(row.getCell(43), 'xlsx') as string as IsMaintenanceContractKey) ?? '',
      });
    }
  });
  const isValid = await validationSchema.isValid(hospitalProductTableData);
  try {
    await validationSchema.validate(hospitalProductTableData, {abortEarly: false});
  } catch (e) {
    (e as {inner: {path: string; message: string}[]}).inner.forEach((error) => {
      // [1].managementId;
      // 正規表現を使用してインデックスとプロパティ名を抽出
      const match = error.path.match(/\[(\d+)\]\.(.+)/);

      let index = -1;
      let propertyName = '';
      let managementId = '';
      if (match) {
        index = Number(match[1]); // インデックス部分
        propertyName = match[2]; // プロパティ名部分
        const data = hospitalProductTableData[index];
        managementId = data.managementId;
        errorMessages.push({managementId: managementId, propertyName: propertyName, message: error.message});
      }
    });
  }

  return {isValid, hospitalProductTableData, errorMessages};
};
