import React from 'react';
import {FaultReceptionHeader, FaultReceptionHeaderProps} from '@components/molecules/FaultReceptionHeader';
import {Box, ScopedCssBaseline, SxProps} from '@mui/material';
import {Footer, FooterProps} from '@Apps/FaultReception/Footer';

type WardAssetMenuTemplateProps = {
  children: React.ReactNode;
  headerProps?: FaultReceptionHeaderProps;
  footerProps?: FooterProps;
  sx?: SxProps;
};
/**
 * Asset病棟ユーザーメニューテンプレート
 * @returns
 */
export const WardAssetMenuTemplate = ({children, headerProps, footerProps, sx}: WardAssetMenuTemplateProps) => {
  return (
    <>
      <ScopedCssBaseline />
      <FaultReceptionHeader {...headerProps} />
      <Box
        sx={{
          display: 'flex',
          overflow: 'auto',
          position: 'fixed',
          width: '100%',
          top: '98px',
          bottom: '128px',
          ...sx,
        }}>
        {children}
      </Box>
      <Footer {...footerProps} />
    </>
  );
};
