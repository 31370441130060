import React from 'react';
import {useNavigate} from 'react-router-dom';
import {WardAssetMenuTemplate} from '@components/templates/WardAssetMenuTemplate';
import {Box, Button} from '@mui/material';

export const ReturnWaitingReceptionRoomSelection = () => {
  const navigate = useNavigate();
  return (
    <WardAssetMenuTemplate
      headerProps={{title: '返却待ち受付'}}
      footerProps={{
        backButtonHandler: () => navigate('/shared/return_waiting_reception/floor_selection'),
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '50vh auto 0',
          transform: 'translateY(-50%)',
          fontWeight: 700,
          fontSize: '40px',
        }}>
        返却待ち受付 小エリア選択
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => navigate('/shared/return_waiting_reception/register/input')}>
          次のページ 入力画面
        </Button>
      </Box>
    </WardAssetMenuTemplate>
  );
};
