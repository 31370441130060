import React, {useEffect, useState} from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  SxProps,
  Theme,
  PaperProps,
  CircularProgress,
} from '@mui/material';
import {NoContentMessage} from './NoContentMessage';

export type TableOrder<T = string> = {
  fieldId: keyof T;
  direction: 'asc' | 'desc';
};

type EnhancedTableProps<T extends object> = {
  data: T[];
  columns: EnhancedColumn<T>[];
  order?: TableOrder<T> | null;
  defaultOrder?: TableOrder<T>;
  onOrderChange?: (column: keyof T, orderDirection: 'asc' | 'desc') => void;

  isLoading?: boolean;
  stickyHeader?: boolean;
  options?: TableOptions;
  noDataComponent?: React.ReactNode;
};

export type EnhancedColumn<T extends object> = {
  title?: string;
  field: keyof T;
  //従来のrenderの機能 コンポーネントを表示したり変換したstring(末尾に円をつける等)を表示する時に使用
  fieldValue?: (row: T) => React.ReactNode;
  noBodyWrap?: boolean; //セル内で改行しないときはtrue
  sorting?: boolean; //ソートを有効にするかどうか
  justifyContentBody?: 'left' | 'right' | 'center';
  sx?: SxProps<Theme>;
};

export type TableOptions = {
  size?: 'small' | 'medium';
  tableSx?: SxProps<Theme>;
  paperProps?: PaperProps;
};

/**
 * 汎用的なテーブルコンポーネント。
 *
 * @todo チェックボックス マウスオーバーでボタンが出る対応 StickerHeader追加 列にSticker追加
 * @type T テーブルの行データの型。
 * @param props.data テーブルに表示するデータの配列。
 * @param props.columns テーブルの列定義の配列。
 * @param props.options テーブル全体のオプションを追加。
 * @example
 *
 * ```tsx
 * type DataType ={
 *  id: number;
 *  name: string;
 * }
 * const data:DataType[] = [
 *   { id: 1, name: 'Aさん', age: 30 },
 *   { id: 2, name: 'Bさん', age: 25 },
 * ];
 * const columns = EnhancedColumn<DataType>[] = [
 *   { title: 'ID', field: 'id', sorting: true },
 *   { title: 'Name', field: 'name',sx: {minWidth: '180px'}, },
 *   { title: 'Age', field: 'age' },
 * ];
 *
 * <EnhancedTable<DataType>
 *   data={data}
 *   columns={columns}
 *   defaultOrder={{ fieldId: 'name', direction: 'asc' }}
 *   onOrderChange={(column, direction) => console.log(column, direction)}
 * />
 * ```
 */
export const EnhancedTable = <T extends object>({
  data,
  columns,
  isLoading,
  noDataComponent,
  order,
  defaultOrder,
  onOrderChange,
  options,
}: EnhancedTableProps<T>) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(defaultOrder?.direction ?? order?.direction ?? 'asc');
  const [orderBy, setOrderBy] = useState<keyof T | null>(
    (defaultOrder?.fieldId as keyof T) ?? (order?.fieldId as keyof T) ?? null
  );

  useEffect(() => {
    if (!order) return;
    setOrderBy(order.fieldId as keyof T);
    setSortOrder(order.direction);
  }, [order]);

  const handleSort = (field: keyof T) => {
    // orderが定義されていたら定義側の情報を優先する
    const isAsc = (order?.fieldId === field || orderBy === field) && (order?.direction || sortOrder) === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setSortOrder(newOrder);
    setOrderBy(field);
    if (onOrderChange) {
      onOrderChange(field, newOrder);
    }
  };

  /** orderが未定義の時にdataをソートする */
  const sortedData = React.useMemo(() => {
    if (order || !orderBy) {
      return data;
    }

    return [...data].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [data, order, orderBy, sortOrder]);

  const hasData = !isLoading && data.length > 0;
  const isNoData = !isLoading && data.length === 0;
  const isDataLoading = !isNoData && isLoading;

  const getColumnValue = (column: EnhancedColumn<T>, row: T) => {
    return column.fieldValue ? column.fieldValue(row) : column.field && row[column.field];
  };

  return (
    <TableContainer component={Paper} {...options?.paperProps}>
      <Table size={options?.size ?? 'small'} sx={{...options?.tableSx}} aria-label="enhanced table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                align={column.justifyContentBody || 'left'}
                sx={{
                  whiteSpace: column.noBodyWrap ? 'nowrap' : 'normal',
                  ...column.sx,
                }}>
                <TableSortLabel
                  active={column.sorting && orderBy === column.field}
                  direction={sortOrder}
                  onClick={() => column.sorting && handleSort(column.field as keyof T)}
                  disabled={!column.sorting}
                  hideSortIcon={column.sorting === false || !column.sorting}>
                  {column.title || ''}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* ローディング中 */}
          {isDataLoading && (
            <CenterTableRow colSpan={columns.length}>
              <CircularProgress sx={{color: '#0052CC', margin: '16px 0'}} />
            </CenterTableRow>
          )}
          {/* 対象のデータなし */}
          {isNoData && (
            <CenterTableRow colSpan={columns.length}>
              {noDataComponent || <NoContentMessage title={'データがありません'} message={''} />}
            </CenterTableRow>
          )}

          {hasData &&
            sortedData.map((row, rowIndex) => (
              <TableRow key={rowIndex} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                {columns.map((column, colIndex) => {
                  const value = getColumnValue(column, row);
                  return (
                    <TableCell
                      key={colIndex}
                      align={column.justifyContentBody || 'left'}
                      sx={{
                        whiteSpace: column.noBodyWrap ? 'nowrap' : 'normal',
                        ...column.sx,
                      }}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type CenterTableRowProps = {
  colSpan: number;
  children: React.ReactNode;
};
const CenterTableRow = ({colSpan, children}: CenterTableRowProps) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        {children}
      </TableCell>
    </TableRow>
  );
};
