import React, {Suspense, useCallback, useEffect, useState} from 'react';
import {BrowserRouter, useRoutes} from 'react-router-dom';
import {nonHeaderRoutes, mainRoutes, rentalRoutes, wardMenuRoutes} from './routes';
import {Helmet} from 'react-helmet-async';
import {withAuth0} from '@modules/auth/hocs/withAuth0';
import {MuiThemeProvider} from '@material-ui/core';
import {theme} from '@atoms/theme';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {setSentryUser} from './configs/sentry';
import {setDatadogRumUser} from './configs/datadog';
import {useInactiveCallback, useSavePreviousLocation} from '@front-libs/core';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import {executeLoadDB} from '@indexedDB/database';
import {useOnlyOnce} from '@front-libs/core';
import {useSignOut} from './modules/auth/hooks';
import {spRoutes} from './spRoutes';
import {MuiV5ThemeProvider} from '@components/atoms/MuiV5ThemeProvider';
import {SystemMaintenanceProvider} from './providers/SystemMaintenanceProvider';
import {tayoriChatbotViewHandler} from './components/molecules/Utils/tayoriChatbotViewHandler';
import Cookies from 'js-cookie';
import {useAuth0} from '@auth0/auth0-react';

highchartsMore(Highcharts);
solidGauge(Highcharts);

function App() {
  const {myInfo} = useMyInfo();

  useOnlyOnce(async () => {
    executeLoadDB();
    const REFRESH_INTERVAL_TIME = 5 * 60 * 1000; // 5分ごとに実行
    setInterval(async () => {
      executeLoadDB();
    }, REFRESH_INTERVAL_TIME);
  });

  if (myInfo) {
    setSentryUser(myInfo);
    setDatadogRumUser(myInfo);
  }

  useSavePreviousLocation();

  const signOut = useSignOut();
  useInactiveCallback(
    myInfo?.sessionExpirationTimeSec ?? null,
    useCallback(() => {
      signOut();
    }, [signOut])
  );

  const [userId, setUserId] = useState<string | undefined>(Cookies.get('userId'));
  const {isAuthenticated, user} = useAuth0();
  useEffect(() => {
    if (isAuthenticated && user) {
      Cookies.set('userId', user.sub ?? '', {domain: import.meta.env.VITE_COMMON_COOKIE, path: '/', expires: 7});
      setUserId(user.sub);
    }
  }, [isAuthenticated, user]);
  useEffect(() => {
    const checkUserIdCookie = () => {
      const currentUserId = Cookies.get('userId');
      if (!currentUserId && userId) {
        signOut();
      }
      setUserId(currentUserId);
    };
    const intervalId = setInterval(checkUserIdCookie, 5000);
    return () => clearInterval(intervalId);
  }, [userId, signOut]);

  const routes = useRoutes([mainRoutes, rentalRoutes, nonHeaderRoutes, spRoutes, wardMenuRoutes]);

  // tayori AIチャットボットの表示制御
  tayoriChatbotViewHandler();

  return (
    <>
      <Helmet>
        {/* react joyrideが使ってるreact floaterで必要 */}
        {/* NOTE:index.html に同様の定義があるのでエラー回避のためコメントアウト */}
        {/* <script>{`const global = globalThis;`}</script> */}
        <script>
          {`!function () {
// iframe内で動作させる場合は以下の1行を削除もしくはコメントアウトしてください
try { if (window.top !== window.self) return; } catch(e) { return; }
if ('TechtouchObject' in window && document.querySelector('script#techtouch-snippet')) return;
window.TechtouchObject = {
organizationUuid: "orga-63f2c199-ab4c-6e17-ba2d-5900dc4498ea",
noBrowserRestriction: true
};
var e = document.createElement("script"); e.async = 1, e.src = "https://apps.techtouch.jp/script/orga-63f2c199-ab4c-6e17-ba2d-5900dc4498ea/main.js?projectUuid=proj-63f417a8-46b4-72c9-11eb-62726202983d"; e.id = "techtouch-snippet";
var t = document.getElementsByTagName("script")[0]; t.parentNode.insertBefore(e, t);

// カスタムプロパティを使用する場合に設定してください
window.TechtouchAdmin={customProperty:{_data:{},get:function(t){return this._data[t]},set:function(t,e){this._data[t]=e},delete:function(t){delete this._data[t]},keys:function(){return Object.keys(this._data)}}};
// 以下で各カスタムプロパティのデータを設定してください
TechtouchAdmin.customProperty.set("223f979f-bdaf-4808-91c8-18e2e28aa465", "${myInfo.hospitalHashId}") // ラベル「病院ID」に対応
}()`}
        </script>
      </Helmet>
      {routes}
    </>
  );
}

const AppWrapper = () => {
  return (
    <Suspense fallback={null}>
      {/* TODO:V5以降時に削除する */}
      <MuiThemeProvider theme={theme}>
        <MuiV5ThemeProvider>
          <BrowserRouter>
            <SystemMaintenanceProvider>
              <App />
            </SystemMaintenanceProvider>
          </BrowserRouter>
        </MuiV5ThemeProvider>
      </MuiThemeProvider>
    </Suspense>
  );
};

export default withAuth0(AppWrapper);
