import {theme} from '@atoms/theme';

// NOTE: 強制貸出機能のFeatureFlag
export const MEIDAI_HIT_4745_FORCE_RENTAL = import.meta.env.VITE_MEIDAI_HIT_4745_FORCE_RENTAL === 'true';

// NOTE: 親子関係にある機器のFeatureFlag
export const MEIDAI_HIT_4888_UNIT_RELATION_FLAG = import.meta.env.VITE_MEIDAI_HIT_4888_UNIT_RELATION === 'true';

// NOTE: 修理にある部品・交換品のFeatureFlag
export const MEIDAI_HIT_4587_CONSUME_PARTS = import.meta.env.VITE_MEIDAI_HIT_4587_CONSUME_PARTS === 'true';

// NOTE: 貸出順番待ち予約のFeatureFlag
export const MEIDAI_HIT_5153_RETURN_PRODUCTS_RESERVATION =
  import.meta.env.VITE_MEIDAI_HIT_5153_RETURN_PRODUCTS_RESERVATION === 'true';

// 親機・子機の非該当を追加するFeatureFlag。親子関係を示すFlagではない
export const MEIDAI_HIT_5368_IS_BASE_UNIT = import.meta.env.VITE_MEIDAI_HIT_5368_IS_BASE_UNIT === 'true';

// 機器一括更新のFeatureFlag。機器一括更新でバグがあるため、既存APIと新規APIを切り替えるためのFeatureFlag
export const MEIDAI_HIT_5390_HP_BULK_UPDATE = import.meta.env.VITE_MEIDAI_HIT_5390_HP_BULK_UPDATE === 'true';

const productStatusType = [
  {
    label: '待機中',
    value: 'ready',
    color: '#3C9E58',
    searchAlias: ['たいきちゅう'],
  },
  {
    label: '貸出中',
    value: 'working',
    color: '#C7243A',
    searchAlias: ['かしだしちゅう'],
  },
  {
    label: '点検待ち',
    value: 'uninspected',
    color: '#826149',
    searchAlias: ['てんけんまち'],
  },
  {
    label: '修理中',
    value: 'repairing',
    color: theme.palette.primary.dark,
    searchAlias: ['しゅうりちゅう'],
  },
  {
    label: '廃棄済み',
    value: 'disabled',
    color: '#727272',
    searchAlias: ['はいきずみ'],
  },
];

export const productStatusOptions = productStatusType.map((item) => ({
  value: item.value,
  label: item.label,
  searchAlias: item.searchAlias,
}));

export const productStatusOptionsWODisabled = productStatusOptions.filter((item) => item.value !== 'disabled');

export const inspectionStatusType = {
  available: {
    label: '利用中',
    color: '#3C9E58',
  },
  draft: {
    label: '下書き',
    color: '#727272',
  },
};
