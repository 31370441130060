import {useCallback} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {isNullish} from '@front-libs/helpers';
import {ResetMyInfo} from '@modules/hospital_users/api';
import Cookies from 'js-cookie';

const CACHE_KEY_PREFIX = '@@auth0spajs@@';
const OLD_CACHE_KEY_PREFIX = '@@hitotsu-front@@';

export const useLogout = () => {
  const {logout, isAuthenticated} = useAuth0();
  return useCallback(() => {
    if (isAuthenticated) {
      // ログアウト時にAuth0のキャッシュを削除
      const userKeys = Object.keys(localStorage).filter(
        (key) => key.startsWith(CACHE_KEY_PREFIX) || key.startsWith(OLD_CACHE_KEY_PREFIX)
      );
      for (const key of userKeys) {
        localStorage.removeItem(key);
      }
      Cookies.remove('userId', {domain: import.meta.env.VITE_COMMON_COOKIE, path: '/'});
      logout({
        returnTo: window.location.origin,
      });
      ResetMyInfo();
    }
  }, [isAuthenticated, logout]);
};
